<template>
  <div class="p-grid" style="width: 100%;margin: 0;justify-content: space-between">
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"
                style="padding-top:0px;background: none;border: none;"/>
    <Button @click="$router.push('/data/detail/dtzz?cm=5&projId='+form.projId)" label="返回" class=" p-button-sm p-button-outlined p-button-secondary"
            style="padding:0px;width:65px;border:1px solid rgba(217, 217, 217, 1);margin-bottom:10px;"/>
  </div>
  <div class="card">
    <div class="p-grid" style="width: 60%;margin: 0 auto">
      <h4 class="p-col-12" style="font-weight: bold ;padding-left: calc(100px + .5rem);margin-bottom: 2rem">收割管理</h4>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">农事类型：</label>
        <div class="p-col">
          <span class="myTagSuccess p-mr-3">收割</span>
        </div>
        <div v-if="suggest!=null" class="p-col-12 p-mt-2" style="padding-left:calc(100px + 0.5rem)">
          <div style="background: lightyellow;padding: .6rem;border-radius: 5px;">
            <i class="pi pi-info-circle" style="position:relative;top:1px"></i>&nbsp;
            专家建议：<span v-for="item in suggest.sensorList">
            {{item.name}}{{item.num}}台;
          </span>
          </div>
        </div>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed"><i class="p-required">*</i>作业方式：</label>
        <div class="p-col">
         <span class="p-mr-3">
            <RadioButton id="har1" name="workType" value="0"
                         :class="{'p-invalid':(v$.form.workType.$invalid && submitted)}"
                         v-model="form.workType"></RadioButton>&nbsp;
                         <label for="har1" class="radio-label">人工</label>
         </span>
          <span>
            <RadioButton id="har2" name="workType" value="1"
                         :class="{'p-invalid':(v$.form.workType.$invalid && submitted)}"
                         v-model="form.workType"></RadioButton>&nbsp;
                         <label for="har2" class="radio-label">机械</label>
         </span>
        </div>
        <span class="err-message" style="padding-left: calc(100px + 1rem);margin-top: .5rem" v-if="v$.form.workType.$invalid && submitted">请选择作业方式</span>
      </div>

      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed"><i class="p-required">*</i>记录时间：</label>
        <div class="p-col">
          <Calendar date-format="yy-mm-dd" :class="{'p-invalid':(v$.form.opeTime.$invalid && submitted)}" v-model="form.opeTime"/>
        </div>
        <span class="err-message" style="padding-left: calc(100px + 1rem);margin-top: .5rem" v-if="v$.form.opeTime.$invalid && submitted">请选择记录时间</span>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">操作人：</label>
        <div class="p-col">
          <InputText v-model="form.opeName"/>
        </div>
      </div>
      <div class="p-field p-grid p-col-12">
        <label class="p-col-fixed">农事照片：</label>
        <div class="p-col p-d-flex">
          <div class="upload-div"
               @click="uploadImg()">
            <img style="width: 30%;height: 30%" src="../../../../../assets/images/add.png">
          </div>
          <div class="p-mr-3" style="position: relative" v-for="(item,index) in form.images">
            <img style="height: 100px;" :src="$minioUrl+item.imgPath">
            <span @click="removeImg(index)"
                  style="position: absolute;width: 20px;height: 20px;background: white;border: 1px solid #CED4DA;border-radius: 20px;right: -7px;top: -7px;display: flex;align-items: center;justify-content: center">
              <i class="pi pi-times" style="font-size: 10px"></i>
            </span>
          </div>
        </div>
      </div>
      <div  class="p-field p-grid p-col-12">
        <label class="p-col-fixed"><i class="p-required">*</i>面积：</label>
        <div class="p-col">
          <InputNumber min="0" style="vertical-align:middle" :class="{'p-invalid':(v$.form.area.$invalid && submitted)}" v-model="form.area"  class="p-mr-3" :minFractionDigits="2"/> 亩
        </div>
        <span class="err-message" style="padding-left: calc(100px + 1rem);margin-top: .5rem" v-if="v$.form.area.$invalid && submitted">请输入面积</span>
      </div>
      <div  class="p-field p-grid p-col-12">
        <label class="p-col-fixed"><i class="p-required">*</i>产量：</label>
        <div class="p-col">
          <InputNumber min="0" style="vertical-align:middle" :class="{'p-invalid':(v$.form.yield.$invalid && submitted)}" v-model="form.yield" class="p-mr-3" :minFractionDigits="2"/> 吨
        </div>
        <span class="err-message" style="padding-left: calc(100px + 1rem);margin-top: .5rem" v-if="v$.form.yield.$invalid && submitted">请输入产量</span>
      </div>
      <div  class="p-field p-grid p-col-12">
        <label class="p-col-fixed"><i class="p-required">*</i>费用：</label>
        <div class="p-col">
          <InputNumber min="0" style="vertical-align:middle" :class="{'p-invalid':(v$.form.price.$invalid && submitted)}" v-model="form.price" class="p-mr-3" :minFractionDigits="2"/> 元
        </div>
        <span class="err-message" style="padding-left: calc(100px + 1rem);margin-top: .5rem" v-if="v$.form.price.$invalid && submitted">请输入费用</span>
      </div>
      <div  class="p-field p-grid p-col-12">
        <label class="p-col-fixed">备注：</label>
        <div class="p-col">
          <Textarea v-model="form.comments" :autoResize="true" rows="4" cols="60" />
        </div>
      </div>
      <div style="margin-left: calc(100px + .5rem)">
        <Button class="p-mr-3" @click="submit">提交</Button>
        <Button class="p-button-outlined p-button-secondary" @click="$router.push('/data/detail/dtzz?cm=5&projId='+form.projId)">取消</Button>
      </div>
    </div>
  </div>
</template>

<script>
import {useVuelidate} from '@vuelidate/core';
import {required} from '@vuelidate/validators';

export default {
  name: 'harvest',
  data(){
    return{
      breadcrumb: {
        home: {label: '种植项目', to: '#'},
        items: [
          {label: '农事记录与指导', to: '/data/overview'},
          {label: '查看数据', to: '#'},
        ],
      },

      suggest:null,
      submitted: false,
      form: {
        id: null,
        projId: null,
        workType: null,
        opeTime: null,
        opeName: JSON.parse(localStorage.getItem('userinfo')).user.name,
        area: null,
        yield: null,
        price:null,
        comments: null,
        images: [],
      },
    }
  },
  setup: () => ({
    v$: useVuelidate(),
  }),
  validations() {
    return {
      form: {
        id: {},
        projId: {},
        workType: {required},
        opeTime: {required},
        opeName: {},
        area: {required},
        yield: {required},
        price: {required},
        comments: {},
      },
    };
  },
  created() {
    this.form.projId = this.$route.query.projId;
    if (localStorage.getItem('suggestInfo' + this.form.projId)) {
      this.suggest = JSON.parse(localStorage.getItem('suggestInfo' + this.form.projId));
    }
    let id = this.$route.query.id;
    if (id) {
      this.getData(id);
    }
  },

  methods: {
    getData(id) {
      this.$axios.get('/recHarvestHarvest/getOne/' ,{
        params:{id:id}
      }).then(res => {
        res.data.opeTime = new Date(res.data.opeTime)
        this.form = res.data;
      });
    },
    uploadImg() {
      const _this = this;
      const input = document.createElement('input');
      input.type = 'file';
      input.onchange = function() {
        let file = this.files[0];
        let formData = new FormData();
        formData.append('file', file);
        _this.$axios.post('/minio/uploadFile', formData, {
          headers: {'Content-Type': 'multipart/form-data'},
        }).then((res) => {
          _this.form.images.push({
            imgPath: res.data,
          });
        });
      };
      input.click();
    },
    removeImg(index) {
      this.form.images.splice(index, 1);
    },
    submit() {
      this.submitted = true;
      if (this.v$.$invalid) {
        return;
      }
      this.$axios.post('/recHarvestHarvest/addOrUpdate', this.form).then(() => {
        this.$toast.add({severity: 'success', summary: '新增成功', life: 3000});
        this.$router.push('/data/detail/dtzz?cm=5&projId=' + this.form.projId);
      });
    },
  },
};
</script>

<style scoped>
label{
  margin:0;
  width: 100px;
  justify-content: flex-end;
}
.p-field>label{
  margin-bottom: 0;
}

</style>